body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
}

/* Ensure vertical alignment for table cells */
.top-align {
    vertical-align: top;
}

/* Page Container Styles */
.scoreboard-page-container {
    padding: 20px;
    margin-top: 100px; /* Adjust as needed to prevent content from being hidden by the navbar */
}

/* New styles for the introductory paragraph on Page_Stats */
.stats-intro-paragraph {
    background-color: peru;
    font-size: 32px; /* Adjust as needed */
    font-weight: bold;
    color: white;
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
}

/* Filter Styles */
.scoreboard-filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
}

.scoreboard-center-filters .filter {
    margin-right: 20px;
}

/* Scatterplot Container Styles */
.scoreboard-scatterplot-container {
    margin-top: 20px; /* Add space above the scatterplot */
    width: 100%;
    overflow-x: auto;
}

/* Responsive Chart */
.responsive-chart {
    width: 100%;
    height: 0;
    padding-bottom: 75%;
    position: relative;
}

    .responsive-chart canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

/* Table Title Container Styles */
.scoreboard-table-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Center Text Styles */
.scoreboard-center-text {
    text-align: center;
    margin-right: 10px;
}

.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-logo {
    width: 50px; /* Adjust the width as needed */
    height: auto;
    margin-left: 10px;
    transform: translateZ(10px); /* Add a 3D effect */
}

.scoreboard-info-section {
    background-color: peru;
    text-align: center;
    padding: 10px 0;
    margin: 10px 0; /* Added margin */
    font-weight: bold;
    font-size: 32px;
    color: white;
}

/* Table Container Styles */
.scoreboard-table-container {
    margin-top: 10px;
    overflow-x: auto;
}

/* Table Styles */
.scoreboard-table {
    display: table;
    width: 100%;
    border-collapse: collapse;
}

.tr {
    display: table-row;
}

.th, .td {
    display: table-cell;
    border: 1px solid peru; /* Thin border with color peru */
    padding: 8px;
    min-width: 150px; /* Adjust as needed */
    vertical-align: top; /* Ensure vertical alignment */
}

/* Header Styles */
.th {
    background-color: peru;
    color: white;
    font-weight: bold;
}

/* Data Row Styles */
.td {
    background-color: white;
}

/* Alternating Row Colors for Data Rows */
.scoreboard-table-container .tr:nth-child(even) .td {
    background-color: #f2f2f2; /* Light grey for even rows */
}

.scoreboard-table-container .tr:nth-child(odd) .td {
    background-color: #ffffff; /* White for odd rows */
}

/* Sticky Header Column */
.th.sticky {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    left: 0;
    background-color: peru; /* Adjust as needed */
    color: white;
    font-weight: bold;
    z-index: 2; /* Higher z-index to ensure it stays on top */
    border-right: 1px solid peru; /* Adjust as needed */
}

/* Sticky Data Column */
.td.sticky {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    left: 0;
    background-color: white; /* Adjust as needed */
    color: black;
    font-weight: normal;
    z-index: 1; /* Lower z-index than header */
    border-right: 1px solid peru; /* Adjust as needed */
}

tr:hover .td {
    background-color: #f1f1f1; /* Light gray background color on hover */
}

/* Average Row Styles */
.average-row {
    font-weight: bold;
    background-color: #f9f9f9;
    border-top: 4px solid peru;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}

    .average-row .td {
        padding: 10px;
        border-right: 1px solid #ddd;
    }

        .average-row .td:last-child {
            border-right: none;
        }

/* Scatterplot Styles */
.scatterplot-container {
    width: 100%;
    height: auto; /* Adjust height for responsiveness */
    margin-bottom: 20px;
    padding: 10px;
}

    .scatterplot-container .plotly {
        width: 100% !important;
        height: 100% !important;
    }

.scatterplot-title {
    font-size: 24px;
    font-family: Arial, sans-serif;
    color: black;
    text-align: center;
    margin-bottom: 10px;
}

.scatterplot-description {
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
}

/* Navigation Menu Styles */
.navbar {
    overflow: hidden;
    background-color: peru; /* Changed to Peru */
    position: fixed; /* Make the navbar fixed to the top */
    top: 0;
    width: 100%; /* Full width */
    height: 60px;
    z-index: 1000; /* Ensure it stays on top */
    display: flex; /* Flexbox for alignment */
    align-items: center; /* Vertically center items */
}

    .navbar img {
        height: 50px; /* Adjust the height as needed */
        margin: 5px 10px; /* Adjust margin as needed */
    }

    .navbar a, .navbar span {
        color: white;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        font-size: 17px;
        font-weight: bold; /* Make the text bold */
    }

        .navbar a:hover, .navbar span:hover {
            color: black; /* Change the text color only on hover */
        }

.dropdown-content {
    display: none;
    position: absolute;
    background: linear-gradient(45deg, #444, #666);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

    .dropdown-content a {
        color: white;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
    }

        .dropdown-content a:hover {
            background: rgba(255, 255, 255, 0.2);
            color: white;
        }

.dropdown:hover .dropdown-content {
    display: block;
}

/* Page Blog Styles */
.page-blog {
    padding-top: 100px; /* Match the height of your navbar */
    text-align: center;
    background-color: #f9f9f9;
}

    .page-blog h1 {
        font-size: 2.5rem;
        margin-bottom: 20px;
        color: #333;
    }

.blog-table-container {
    margin: 0 auto;
    max-width: 1200px; /* Limit the width for better readability */
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.blog-table {
    width: 100%;
    border-collapse: collapse;
}

    .blog-table th, .blog-table td {
        padding: 15px;
        text-align: left;
        vertical-align: top;
        border-bottom: 1px solid #ddd;
    }

    .blog-table th {
        background-color: peru;
        color: white;
        font-weight: bold;
    }

    .blog-table tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    .blog-table tr:hover {
        background-color: #e9e9e9;
    }

.blog-image {
    width: 100px;
    height: auto;
    border-radius: 4px;
    transition: transform 0.3s ease;
}

    .blog-image:hover {
        transform: scale(1.1);
    }

@media (max-width: 768px) {
    .blog-table-container {
        padding: 10px;
    }

    .blog-table th, .blog-table td {
        padding: 10px;
    }

    .page-blog h1 {
        font-size: 2rem;
    }

    /* Responsive Blog Table */
    .blog-table th, .blog-table td {
        display: block;
        width: 100%;
        box-sizing: border-box;
    }

    .blog-table thead {
        display: none;
    }

    .blog-table tr {
        display: block;
        margin-bottom: 15px;
    }

    .blog-table td {
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

        .blog-table td::before {
            content: attr(data-label);
            position: absolute;
            left: 0;
            width: 50%;
            padding-left: 15px;
            font-weight: bold;
            text-align: left;
        }

    .blog-image {
        max-width: 50px;
        height: auto;
    }
}

/* Home Page Video Background Container */
.video-background-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Top half of the page */
    overflow: hidden;
}

.video-background {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    filter: brightness(100%);
    transform: translate(-50%, -50%);
    pointer-events: none; /* Prevent interaction */
}

.page-content {
    position: relative;
    z-index: 2;
    padding: 20px;
    background-color: white; /* Adjust as needed */
}

/* Message Styles */
.welcome-message {
    position: absolute;
    top: 50%;
    left: 10%; /* Adjust this value to position the message more to the left */
    transform: translateY(-50%);
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    z-index: 10;
}

.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    background-color: #f9f9f9;
}

.about-video-container {
    width: 100%;
    height: 50vh;
    overflow: hidden;
}

.about-background-video {
    width: 100%;
    height: auto;
    object-fit: cover;
    pointer-events: none; /* Prevent interaction */
}

.about-content {
    padding: 20px;
    max-width: 800px;
    margin: 20px auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

    .about-content h1 {
        color: #cd853f; /* Peru color */
        font-size: 2.5em;
        margin-bottom: 20px;
    }

    .about-content p {
        color: #333;
        font-size: 1.2em;
        line-height: 1.6;
        margin-bottom: 20px;
    }

.contact-information {
    margin-top: 20px;
    font-size: 1.2em;
    padding-bottom: 80px; /* Add padding to create space above the footer */
}

    .contact-information a {
        color: #cd853f; /* Peru color */
        text-decoration: none;
    }

        .contact-information a:hover {
            text-decoration: underline;
        }

.google-container {
    display: flex;
    flex-direction: column;
    padding: 60px 20px 20px; /* Larger padding at the top */
    margin-top: 60px; /* Larger margin at the top */
}

.google-text-section {
    flex-basis: 100%;
    margin-bottom: 20px;
    padding: 20px;
    background-color: white;
    font-family: 'Peru', sans-serif;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.google-sidebar {
    width: 250px;
    margin-right: 20px;
}

.google-chart {
    flex-grow: 1;
}

.google-multi-select {
    width: 100%;
}

/* Card Search Page Styles */
.page-card-search {
    position: relative;
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: white;
    overflow: hidden;
    height: 100vh;
}

.video-background-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.video-background {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none; /* Prevent interaction */
}

.content {
    position: relative;
    z-index: 2;
    padding-top: 10%; /* Adjusted to move the content upwards */
}

    .content h1 {
        font-size: 3rem;
        margin-bottom: 20px; /* Adjusted margin */
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    }

.search-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px; /* Adjusted gap */
    margin-bottom: 20px; /* Adjusted margin */
}

    .search-box input {
        padding: 12px 15px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.8);
        color: #333;
    }

    .search-box button {
        padding: 12px 20px;
        font-size: 16px;
        cursor: pointer;
        background-color: peru;
        color: white;
        border: none;
        border-radius: 4px;
        transition: background-color 0.3s;
    }

        .search-box button:hover {
            background-color: #a8642d;
        }

.results iframe {
    border: none;
    width: 100%;
    height: 600px;
}

.form-row {
    display: flex;
    justify-content: center;
    gap: 10px; /* Adjusted gap */
    margin-bottom: 10px;
}

    .form-row input {
        flex: 1;
        margin-right: 10px;
    }

        .form-row input:last-child {
            margin-right: 0;
        }

button {
    width: 25%;
    padding: 12px 20px;
    background-color: peru;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    align-self: safe center; /* Ensures the button is only as wide as needed */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .scoreboard-filters {
        flex-direction: column;
        align-items: center;
    }

    .scoreboard-filter {
        margin-bottom: 15px;
        width: 100%;
    }

    .scoreboard-scatterplot-container {
        width: 100%;
        overflow-x: auto;
    }

    .tr, .th, .td {
        display: block;
        text-align: left;
    }

    .scoreboard-table {
        display: block;
    }

    .scoreboard-page-container {
        padding: 10px;
    }

    .scoreboard-text-section {
        font-size: 12px;
        font-style: italic;
    }

    .scoreboard-top-paragraph {
        background-color: peru;
        font-size: 32px; /* Adjust as needed */
        font-weight: bold;
        color: white;
    }

    .stats-intro-paragraph {
        background-color: peru;
        font-size: 32px; /* Adjust as needed */
        font-weight: bold;
        color: white;
        text-align: center;
        padding: 10px;
        margin-bottom: 20px;
    }

    .stats-paragraph-section {
        font-size: 12px;
        font-style: italic;
    }

    .search-box {
        flex-direction: column;
        align-items: center;
    }

    .form-row {
        flex-direction: column;
    }

        .form-row input {
            margin-right: 0;
            width: 100%;
            margin-bottom: 10px;
        }

    .search-box button {
        width: 100%;
    }
}

/* TrendsChart styles for mobile responsiveness */
.trends-chart {
    padding-top: 50px;
    padding: 20px;
}

    .trends-chart h2 {
        font-size: 1.5em;
        text-align: center;
    }

    .trends-chart p {
        font-size: 1em;
        text-align: center;
        margin: 0 auto;
        max-width: 90%;
    }

    .trends-chart .checkbox-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .trends-chart .checkbox-item {
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .trends-chart .chart-container {
        width: 100%;
        overflow-x: auto;
    }

@media (max-width: 768px) {
    .trends-chart h2 {
        font-size: 1.2em;
    }

    .trends-chart p {
        font-size: 0.9em;
    }

    .trends-chart {
        padding: 10px;
    }
}

/* Dropdown styles for TrendsChart */
.dropdown-container {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dropdown-select {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
}

@media (max-width: 768px) {
    .dropdown-select {
        max-width: 100%;
        font-size: 0.9em;
    }
}

/* Reset button styles */
.reset-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: peru;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s;
}

    .reset-button:hover {
        background-color: #a8642d;
    }

@media (max-width: 600px) {
    .blog-table th, .blog-table td {
        display: block;
        width: 100%;
        box-sizing: border-box;
    }

    .blog-table thead {
        display: none;
    }

    .blog-table tr {
        display: block;
        margin-bottom: 15px;
    }

    .blog-table td {
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

        .blog-table td::before {
            content: attr(data-label);
            position: absolute;
            left: 0;
            width: 50%;
            padding-left: 15px;
            font-weight: bold;
            text-align: left;
        }

    .blog-image {
        max-width: 50px;
        height: auto;
    }
}

/* About Page Styles */
@media (max-width: 768px) {
    .about-container {
        padding: 10px;
    }

    .about-content {
        padding: 10px;
    }

        .about-content h1 {
            font-size: 2em;
        }

        .about-content p {
            font-size: 1em;
        }

    .contact-information {
        font-size: 1em;
    }

    .about-video-container {
        height: 50vh;
    }

    .about-background-video {
        height: 50vh;
    }
}

@media (max-width: 480px) {
    .about-content h1 {
        font-size: 1.5em;
    }

    .about-content p {
        font-size: 0.9em;
    }

    .contact-information {
        font-size: 0.9em;
    }

    .about-video-container {
        height: 50vh;
    }

    .about-background-video {
        height: 50vh;
    }
}

/* Add these styles to your CSS file for Cognito Log-in*/
.peru-text {
    color: peru;
}

.peru-button {
    background-color: peru;
    color: white; /* Change button text color to white */
    margin: 20px;
    font-size: 0.8rem;
    padding: 5px 10px;
    margin-top: 20px;
}

/* Existing styles for Page_CardSearch remain unchanged */

/* Adjustments for mobile visibility */
@media (max-width: 768px) {
    .content {
        padding-top: 5%; /* Reduce top padding for mobile */
    }

        .content h1 {
            font-size: 2rem; /* Smaller font size for mobile */
            margin-bottom: 10px; /* Reduce margin for mobile */
        }

    .search-box {
        gap: 5px; /* Reduce gap between inputs */
        margin-bottom: 10px; /* Reduce margin for mobile */
    }

    .form-row {
        flex-direction: column;
        gap: 5px; /* Reduce gap between inputs */
    }

        .form-row input {
            margin-bottom: 5px; /* Reduce margin for mobile */
        }

    .glossary {
        padding: 10px; /* Add padding for mobile */
        font-size: 0.9rem; /* Smaller font size for mobile */
        text-align: left; /* Left align for readability */
    }
}

/* Search Results Styles */
.search-results {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100% - 60px);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    overflow-y: auto;
    padding: 20px;
    z-index: 1001;
}

    .search-results .close-button {
        position: absolute;
        top: 10px;
        right: 20px;
        background-color: red;
        color: white;
        border: none;
        padding: 10px;
        cursor: pointer;
    }

    .search-results h2 {
        margin-top: 0;
    }

    .search-results ul {
        list-style: none;
        padding: 0;
    }

    .search-results li {
        margin: 10px 0;
    }

        .search-results li a {
            color: white;
            text-decoration: none;
        }

            .search-results li a:hover {
                text-decoration: underline;
            }

@media (max-width: 768px) {
    .search-results {
        padding: 10px;
    }

        .search-results h2 {
            font-size: 1.5rem;
        }

        .search-results ul {
            padding: 0;
        }

        .search-results li {
            margin: 5px 0;
        }

        .search-results .close-button {
            padding: 8px;
        }
}

@media (max-width: 480px) {
    .search-results h2 {
        font-size: 1.2rem;
    }

    .search-results li {
        margin: 5px 0;
    }

    .search-results .close-button {
        padding: 5px;
    }
}

/* Navbar styles for mobile responsiveness */
@media (max-width: 768px) {
    .navbar {
        flex-direction: column;
        align-items: flex-start;
    }

        .navbar .search-bar {
            width: 100%;
            margin-top: 10px;
        }

        .navbar .login-link {
            margin-top: 10px;
            align-self: flex-end;
        }
}

@media (max-width: 480px) {
    .navbar .search-bar {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

        .navbar .search-bar input {
            flex: 1;
            margin-right: 5px;
        }

    .navbar .login-link {
        align-self: center;
    }
}

/* Footer Bar */
.footer-bar {
    width: 100%;
    height: 50px;
    background-color: peru;
    position: fixed;
    bottom: 0;
    left: 0;
}

.products-page {
    padding: 20px;
    padding-top: 100px; /* Ensure space under the navigation bar */
}

.products-title {
    font-size: 32px;
    color: peru;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.tag-filter {
    margin-bottom: 20px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.products-subscribe {
    text-align: center;
    margin-bottom: 20px;
}

.subscribe-link {
    color: peru;
    text-decoration: underline;
}

.products-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.product-card {
    flex: 1 1 calc(25% - 20px);
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    height: 350px;
    justify-content: space-between;
    cursor: pointer;
    transition: transform 0.3s ease; /* Add transition for smooth scaling */
}

    .product-card.highlight {
        border: 2px solid red; /* Highlight border color */
        box-shadow: 0 0 15px rgba(255, 0, 0, 0.5); /* Highlight shadow */
        transform: scale(1.05); /* Slightly scale up the highlighted product */
        animation: highlight-animation 1s ease-in-out infinite alternate;
    }

    .product-card:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

@keyframes highlight-animation {
    0% {
        background-color: white;
    }

    100% {
        background-color: #ffeb3b;
    }
}

.product-image {
    width: 100%;
    height: auto;
    max-height: 150px; /* Shrink the image to make it more viewable */
    object-fit: contain;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
}

.product-title {
    font-size: 1.2em;
    margin: 10px 0;
    text-align: center;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.product-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.product-price {
    font-size: 1em;
    color: #888;
    margin: 0;
}

.product-link {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
    margin-top: 10px;
    word-break: break-word;
}

/* Modal styles */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 320px; /* Adjusted width */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-height: 400px; /* Adjusted max-height to fit the content */
    overflow: auto; /* Enable scrolling if content overflows */
    text-align: center;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

    .close:hover,
    .close:focus {
        color: #bbb;
        text-decoration: none;
        cursor: pointer;
    }

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .product-card {
        flex: 1 1 calc(50% - 20px); /* 2 products per row */
    }
}

@media (max-width: 480px) {
    .product-card {
        flex: 1 1 calc(100% - 20px); /* 1 product per row */
    }
}

.page-returns {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 100px; /* Ensure space under the navigation bar */
}

.returns-title {
    color: peru;
    text-align: center;
    margin-bottom: 20px;
}

.dropdown-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.dropdown-select {
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.chart-container {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Add gap to prevent overlapping */
}

.chart-subplot {
    height: 450px; /* Increased height */
}

.chart-title {
    text-align: center;
    color: peru;
    margin-bottom: 10px;
}

.performance-container {
    margin-top: 150px; /* Add margin to prevent overlapping */
}

.info-paragraph {
    color: peru;
    font-style: italic;
    text-align: center;
    margin-top: 20px;
}

@media (min-width: 768px) {
    .chart-container {
        flex-direction: row;
        justify-content: space-between;
    }

    .chart-subplot {
        flex: 1;
        margin-right: 10px;
        height: 500px; /* Increased height for larger screens */
    }

        .chart-subplot:last-child {
            margin-right: 0;
        }
}

@media (max-width: 768px) {
    .page-returns {
        padding: 10px;
    }

    .returns-title {
        font-size: 1.5em;
    }

    .dropdown-select {
        width: 100%;
    }

    .chart-subplot {
        height: 300px; /* Decreased height for mobile */
    }

    .performance-container {
        margin-top: 50px; /* Adjust margin for mobile */
    }

    .info-paragraph {
        font-size: 0.9em;
        margin-top: 10px;
    }
}

.product-image.loading {
    filter: blur(10px);
    transition: filter 0.2s;
}

.product-image.loaded {
    filter: blur(0);
}

/* Existing styles... */

/* Flex layout for the auth container */
.auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    min-height: 100vh; /* Ensure it takes the full height of the screen */
}

/* Responsive button styles */
.signout-button {
    margin: 20px;
    font-size: 1rem;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

    .signout-button:hover {
        background-color: #0056b3;
    }

/* Mobile styles */
@media (max-width: 768px) {
    .auth-container {
        padding: 10px;
    }

    .signout-button {
        width: 100%;
        padding: 12px;
        font-size: 1.2rem;
        background-color: peru;
    }

    .App-header h1 {
        font-size: 1.5rem;
        color: peru;
    }
}

@media (max-width: 480px) {
    .signout-button {
        padding: 14px;
        font-size: 1.4rem;
    }

    .App-header h1 {
        font-size: 1.2rem;
        color: peru;
    }
}

.filter-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px; /* Increase max-width to accommodate all filters */
    margin: 0 auto;
    padding: 20px;
    border: 2px solid peru;
    border-radius: 8px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    box-sizing: border-box; /* Ensures padding is included within the width */
}

.filter-title {
    font-size: 24px;
    font-weight: bold;
    color: peru;
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
}

.filter-container {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    justify-content: center; /* Center items horizontally */
    gap: 20px;
    width: 100%;
    box-sizing: border-box; /* Ensures padding is included within the width */
}

.search-box input,
.price-filter input,
.tag-filter .basic-multi-select__control {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 40px; /* Ensures uniform height */
    box-sizing: border-box;
}

.search-box input {
    width: 300px; /* Further increase width to display full placeholder text */
}

.price-filter input,
.tag-filter .basic-multi-select__control {
    width: 200px;
}

.price-filter {
    display: flex;
    gap: 10px;
}

.reset-button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: peru;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    box-sizing: border-box;
    height: 40px; /* Ensures uniform height with input fields */
    margin-top: 20px; /* Adds margin to separate from input fields */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .filter-container {
        flex-direction: column;
        align-items: center; /* Center items vertically */
        gap: 15px;
    }

    .search-box input,
    .price-filter input,
    .tag-filter .basic-multi-select__control,
    .reset-button {
        width: 100%; /* Full width on mobile */
    }

    .price-filter {
        flex-direction: column;
        gap: 15px;
    }

    .reset-button {
        margin-top: 15px; /* Adjust margin for better spacing */
    }
}

.products-page {
    padding: 20px;
    padding-top: 100px; /* Ensure space under the navigation bar */
}

.products-title {
    font-size: 32px;
    color: peru;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
}

.filter-wrapper {
    border: 2px solid peru;
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

    .filter-wrapper .player-select, .filter-wrapper .sport-select {
        width: 100%; /* Full width on mobile */
        max-width: 300px; /* Restrict maximum width */
        margin-right: 10px;
        margin-bottom: 10px; /* Space between items on mobile */
    }

    .filter-wrapper .reset-button {
        padding: 10px;
        font-size: 14px;
        cursor: pointer;
        width: 100%; /* Full width on mobile */
        max-width: 300px; /* Restrict maximum width */
    }

.scatterplot-title {
    font-size: 24px;
    font-family: Arial, sans-serif;
    color: black;
    text-align: center;
    margin-bottom: 10px;
}

.scatterplot-description {
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
}

.footer {
    background-color: peru;
    color: white;
    text-align: center;
    padding: 10px 0;
    position: fixed;
    width: 100%;
    bottom: 0;
}

.social-icons {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 20px; /* Add this line to create more space between the logo and the icons */
}

    .social-icons a img {
        height: 20px; /* Adjust the size as needed */
        width: 20px; /* Adjust the size as needed */
    }

    .social-icons a:hover img {
        filter: brightness(0.8);
    }

.full-blog-summary {
    padding: 20px;
    margin: 20px auto; /* Add margin here */
    max-width: 800px;
    line-height: 1.6;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.blog-title {
    font-size: 2em;
    margin-bottom: 20px;
}

.blog-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.blog-subtitle {
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 10px;
}

.blog-subsubtitle {
    font-size: 1.25em;
    margin-top: 15px;
    margin-bottom: 10px;
}

.blog-paragraph {
    margin-bottom: 15px;
    text-align: justify;
}

.blog-list {
    margin-left: 20px;
    margin-bottom: 20px;
}

.share-buttons {
    margin-top: 20px;
    text-align: center;
}

.share-button {
    margin: 0 5px;
}

@media (max-width: 768px) {
    .full-blog-summary {
        padding: 15px;
        max-width: 100%;
    }

    .blog-title {
        font-size: 1.5em;
    }

    .blog-subtitle {
        font-size: 1.25em;
    }

    .blog-subsubtitle {
        font-size: 1.1em;
    }
}

/* Promo Modal Styles */
.promo-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    max-width: 500px;
    width: 90%;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.promo-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 999;
}

.modal-content {
    text-align: center;
    color: peru; /* Apply Peru color to all text in modal-content */
}

    .modal-content h2 {
        margin-bottom: 20px;
        color: peru; /* Ensure modal header text is colored Peru */
    }

    .modal-content p {
        margin-bottom: 20px;
        color: peru; /* Ensure modal paragraph text is colored Peru */
    }

    .modal-content a {
        display: inline-block;
        margin-bottom: 20px;
        color: peru; /* Ensure links are also colored Peru */
        text-decoration: none;
    }

    .modal-content button {
        padding: 10px 20px;
        background: peru; /* Change button background to Peru */
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }

    .modal-content .coupon-text {
        font-weight: bold; /* Make the text bold */
        text-decoration: underline; /* Underline the text */
    }

.favorite-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 24px;
    cursor: pointer;
    z-index: 10; /* Ensure it is above other elements */
}

.scoreboard-table {
    display: table;
    width: 100%;
    border-collapse: collapse;
}

.tr {
    display: table-row;
}

.th, .td {
    display: table-cell;
    border: 1px solid peru; /* Thin border with color peru */
    padding: 8px;
    min-width: 150px; /* Adjust as needed */
    vertical-align: top; /* Ensure vertical alignment */
}

/* Header Styles */
.th {
    background-color: peru;
    color: white;
    font-weight: bold;
}

/* Data Row Styles */
.td {
    background-color: white;
}

/* Alternating Row Colors for Data Rows */
.scoreboard-table-container .tr:nth-child(even) .td {
    background-color: #f2f2f2; /* Light grey for even rows */
}

.scoreboard-table-container .tr:nth-child(odd) .td {
    background-color: #ffffff; /* White for odd rows */
}

/* Sticky Header Column */
.th.sticky {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    left: 0;
    background-color: peru; /* Adjust as needed */
    color: white;
    font-weight: bold;
    z-index: 2; /* Higher z-index to ensure it stays on top */
    border-right: 1px solid peru; /* Adjust as needed */
}

/* Sticky Data Column */
.td.sticky {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    left: 0;
    background-color: white; /* Adjust as needed */
    color: black;
    font-weight: normal;
    z-index: 1; /* Lower z-index than header */
    border-right: 1px solid peru; /* Adjust as needed */
}

tr:hover .td {
    background-color: #f1f1f1; /* Light gray background color on hover */
}

.average-row {
    font-weight: bold;
    background-color: #f9f9f9;
    border-top: 4px solid peru;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}

    .average-row .td {
        padding: 10px;
        border-right: 1px solid #ddd;
    }

        .average-row .td:last-child {
            border-right: none;
        }

.google-news-page {
    padding: 20px;
    padding-top: 100px; /* Add more space under the navigation bar */
}

    .google-news-page h1 {
        text-align: center;
    }

.instruction-text {
    color: peru;
    font-weight: bold;
    text-align: center;
}

.input-container {
    border: 2px solid peru;
    padding: 20px;
    margin-top: 20px;
    text-align: center;
}

.input-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 10px; /* Add gap between input elements */
}

.player-label {
    margin-right: 10px;
    color: peru;
    font-weight: bold;
}

.player-input {
    padding: 5px;
    width: 400px; /* Increased width */
}

.button-widget {
    display: flex;
    flex-direction: column; /* Arrange buttons vertically */
    gap: 10px; /* Add gap between buttons */
    width: 400px; /* Increase width to accommodate button text */
}

.widget-button {
    padding: 10px; /* Increase padding for better appearance */
    background-color: peru;
    color: white;
    font-weight: bold;
    border: none;
    cursor: pointer;
    text-align: center;
}

.button-container {
    text-align: center;
    margin-bottom: 20px;
}

.articles-container {
    margin-top: 20px;
    text-align: center;
    border: 2px solid peru;
    padding: 20px;
}

.get-articles-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: peru;
    color: white;
    font-weight: bold;
    border: none;
    cursor: pointer;
}

.articles-list {
    margin-top: 20px;
    text-align: left;
}

.articles-table {
    width: 100%;
    border-collapse: collapse;
}

    .articles-table th,
    .articles-table td {
        border: 1px solid peru;
        padding: 8px;
        text-align: left;
    }

    .articles-table th {
        background-color: peru;
        color: white;
    }

.sports-news-table {
    border-collapse: collapse;
    width: 100%;
    border: 4px solid peru;
}

    .sports-news-table th,
    .sports-news-table td {
        border-top: 2px solid peru;
        border-bottom: 2px solid peru;
        padding: 8px;
        text-align: left;
    }

    .sports-news-table th {
        background-color: #f2f2f2;
    }

    .sports-news-table td a {
        color: #1a0dab;
        text-decoration: none;
    }

        .sports-news-table td a:hover {
            text-decoration: underline;
        }

/* Centering the container and making the page responsive */
.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
}

.input-container {
    border: 1px solid peru;
    padding: 10px;
    width: 80%;
    max-width: 600px;
    margin: 0 auto;
    box-sizing: border-box;
}

.input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-input {
    width: 100%;
    max-width: 500px;
}

.reset-button {
    background-color: peru;
    color: white;
    margin-top: 10px;
    padding: 10px;
}

.card-prices-table-container {
    border: 4px solid peru;
    padding: 10px;
    margin-top: 20px;
    width: 100%;
    max-width: 800px;
    box-sizing: border-box;
}

.card-prices-table {
    width: 100%;
    border-collapse: collapse;
}

    .card-prices-table th, .card-prices-table td {
        border: 1px solid peru;
        padding: 10px;
    }

.return-link {
    align-self: flex-start;
    margin-bottom: 20px;
}

.center-text {
    text-align: center;
}

.example-text {
    margin-top: 10px;
    text-align: center;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .card-input, .reset-button {
        width: 100%;
    }
}

.aiSearch-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px; /* Adjust as needed */
}

.aiSearch-search-box-container {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the content horizontally */
}

.aiSearch-image {
    width: 100px; /* Adjust the width as needed */
    height: 100px; /* Adjust the height as needed */
    border-radius: 50%; /* Rounds the edges */
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3); /* 3D effect */
    margin: 0 20px; /* Space between images and search box */
}

.aiSearch-articles-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

    .aiSearch-articles-table th, .aiSearch-articles-table td {
        border: 1px solid #ddd;
        padding: 8px;
    }

    .aiSearch-articles-table th {
        background-color: peru;
        color: white;
    }

.aiSearch-all-text-container, .aiSearch-word-frequency-container, .aiSearch-word-frequency-display {
    margin-top: 20px;
    text-align: left;
    max-width: 100%;
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto;
    height: 200px; /* Set a fixed height for scrolling */
}

.aiSearch-export-container {
    margin-top: 20px;
    text-align: center;
}

.aiSearch-export-button {
    background-color: peru;
    color: white;
    padding: 10px 20px;
    min-width: 150px; /* Set a minimum width */
    border: none;
    cursor: pointer;
    text-align: center; /* Ensure text is centered */
}

.aiSearch-response-container {
    border: 5px solid peru;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3); /* 3D effect */
    padding: 10px;
    margin: 20px 0;
}

.aiSearch-response-label {
    color: peru;
    font-weight: bold;
    font-size: 1.5em; /* Increase font size */
}

.aiSearch-articles-container {
    max-height: none;
    overflow: visible;
}

.input-container-with-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.side-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.card-input {
    width: calc(1ch * 80); /* Adjust width to fit 80 characters */
    padding: 10px;
    font-size: 16px;
}

.input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.announcement-container {
    position: absolute;
    top: 100px; /* Adjust according to your layout */
    right: 20px; /* Adjust according to your layout */
    width: 300px; /* Adjust according to your layout */
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    z-index: 10; /* Ensure it stays on top */
    overflow: visible; /* Ensure the arrow is visible */
}

.announcement-arrow {
    position: absolute;
    top: -20px; /* Adjust according to your layout */
    right: 20px; /* Adjust according to your layout */
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid white;
    z-index: 11; /* Ensure it stays on top */
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
}

.announcement-images {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

    .announcement-images img {
        width: 30%;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

.announcement-message {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.page-collection-custom {
    text-align: center;
    padding: 20px;
    padding-top: 100px; /* Shift the page down */
}

.page-title-custom {
    font-size: 2em;
}

.page-description-custom {
    font-weight: bold;
    color: peru;
}

.file-upload-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.file-input-custom {
    margin-right: 10px;
}

.sample-csv-link {
    color: peru;
    font-weight: bold;
    text-decoration: none;
}

    .sample-csv-link:hover {
        text-decoration: underline;
    }

.summary-container-custom {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 20px;
    font-weight: bold;
}

.search-container-custom {
    border: 2px solid peru;
    padding: 10px;
    margin-bottom: 20px;
    text-align: left;
}

.search-input-custom {
    width: 200px;
    padding: 5px;
    margin-left: 10px;
}

.gain-positive {
    color: green;
}

.gain-negative {
    color: red;
}

.table-container-custom {
    width: 80%;
    margin: 20px auto;
    max-height: 400px; /* Adjust height as needed */
    overflow-y: auto;
    border: 2px solid peru;
    border-collapse: collapse;
}

.collection-table-custom {
    width: 100%;
    border-collapse: collapse;
}

    .collection-table-custom th, .collection-table-custom td {
        border-top: 2px solid peru;
        border-bottom: 2px solid peru;
        text-align: left;
        padding: 8px;
    }

    .collection-table-custom th {
        background-color: #f4f4f4;
    }

.input-custom {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
    box-sizing: border-box;
}

.input-title-custom {
    width: 500px; /* Adjust width to fit 80 characters */
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
    box-sizing: border-box;
}

.input-price-custom {
    width: 100px; /* Adjust width to fit price values */
}

.input-cost-custom {
    width: 100px; /* Adjust width to fit cost values */
}

.input-qty-custom {
    width: 50px; /* Adjust width to fit quantity values */
}

.input-date-custom {
    width: 130px; /* Adjust width to fit date values */
}

.base-name-column {
    font-size: 8px;
    word-wrap: break-word;
    max-width: 100px; /* Adjust max width as needed */
}

.add-row-button-custom, .delete-button-custom, .export-button-custom {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: peru;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px; /* Add space between buttons */
}

    .add-row-button-custom:hover, .delete-button-custom:hover, .export-button-custom:hover {
        background-color: #b87333; /* Slightly darker shade of Peru */
    }

.news-search-link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

    .news-search-link:hover {
        text-decoration: none;
    }

/* Add these styles at the bottom of App.css */

/* Styles for the search container */
.search-container-custom {
    border: 3px solid peru;
    padding: 10px;
    margin: 10px 0;
}

    .search-container-custom label {
        margin-right: 10px;
        font-weight: bold;
    }

.search-input-custom {
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Styles for CardPrices page */
.cardPrices-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
}

.cardPrices-search-box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.cardPrices-results ul {
    list-style-type: none;
    padding: 0;
}

.cardPrices-results li {
    background-color: #f9f9f9;
    margin: 5px 0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.reset-button,
.fetch-card-prices-button {
    padding: 10px 10px;
    font-size: 14px;
    height: 40px; /* Set a fixed height */
    width: auto;
}

.sort-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5px;
}

.sort-button {
    background: none;
    border: none;
    color: peru;
    font-size: 10px; /* Adjust size as needed */
    padding: 0;
    margin-left: 5px; /* Adjust spacing as needed */
    cursor: pointer;
}

    .sort-button:focus {
        outline: none;
    }

.ascending, .descending {
    display: block;
    line-height: 1;
}

.collection-table-custom th {
    white-space: nowrap;
}

.page-collection-custom {
    text-align: center;
    padding: 20px;
    padding-top: 100px; /* Shift the page down */
}

.page-title-custom {
    font-size: 2em;
}

.page-description-custom {
    font-weight: bold;
    color: peru;
}

.file-upload-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.file-input-custom {
    margin-right: 10px;
}

.sample-csv-link {
    color: peru;
    font-weight: bold;
    text-decoration: none;
}

    .sample-csv-link:hover {
        text-decoration: underline;
    }

.summary-container-custom {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 20px;
    font-weight: bold;
}

.search-container-custom {
    border: 2px solid peru;
    padding: 10px;
    margin-bottom: 20px;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.search-input-custom {
    width: 200px;
    padding: 5px;
    margin-left: 10px;
}

.gain-positive {
    color: green;
}

.gain-negative {
    color: red;
}

.table-container-custom {
    width: 100%;
    margin: 20px auto;
    max-height: 400px; /* Adjust height as needed */
    overflow-y: auto;
    border: 2px solid peru;
    border-collapse: collapse;
}

.collection-table-custom {
    width: 100%;
    border-collapse: collapse;
}

    .collection-table-custom th, .collection-table-custom td {
        border-top: 2px solid peru;
        border-bottom: 2px solid peru;
        text-align: left;
        padding: 8px;
    }

    .collection-table-custom th {
        background-color: #f4f4f4;
    }

.input-custom {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
    box-sizing: border-box;
}

.input-title-custom {
    width: 500px; /* Adjust width to fit 80 characters */
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
    box-sizing: border-box;
}

.input-price-custom {
    width: 100px; /* Adjust width to fit price values */
}

.input-cost-custom {
    width: 100px; /* Adjust width to fit cost values */
}

.input-qty-custom {
    width: 50px; /* Adjust width to fit quantity values */
}

.input-date-custom {
    width: 130px; /* Adjust width to fit date values */
}

.base-name-column {
    font-size: 8px;
    word-wrap: break-word;
    max-width: 100px; /* Adjust max width as needed */
}

.add-row-button-custom, .delete-button-custom, .export-button-custom {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: peru;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px; /* Add space between buttons */
}

    .add-row-button-custom:hover, .delete-button-custom:hover, .export-button-custom:hover {
        background-color: #b87333; /* Slightly darker shade of Peru */
    }

.news-search-link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

    .news-search-link:hover {
        text-decoration: none;
    }

@media (max-width: 768px) {
    .search-container-custom {
        flex-direction: column;
        align-items: flex-start;
    }

    .summary-container-custom {
        flex-direction: column;
        gap: 10px;
    }

    .table-container-custom {
        width: 100%;
    }

    .input-title-custom {
        width: 100%;
    }

    .base-name-column {
        max-width: 100%;
    }

    .add-row-button-custom, .delete-button-custom, .export-button-custom {
        width: 100%;
        margin-right: 0;
    }
}

@media (max-width: 480px) {
    .page-title-custom {
        font-size: 1.5em;
    }

    .page-description-custom {
        font-size: 1em;
    }

    .summary-container-custom {
        flex-direction: column;
        align-items: flex-start;
    }

    .search-container-custom {
        flex-direction: column;
        gap: 10px;
    }

    .collection-table-custom th, .collection-table-custom td {
        font-size: 12px;
    }

    .add-row-button-custom, .delete-button-custom, .export-button-custom {
        width: 100%;
        margin-right: 0;
        padding: 10px;
    }
}

.input-title-long {
    width: 400px; /* Adjust width as necessary */
    max-width: 100%;
}

.title-container {
    display: flex;
    align-items: center;
}

.input-container-with-image {
    display: flex;
    align-items: center;
}

.side-image {
    width: 50px; /* Adjust the size as needed */
    height: 50px; /* Adjust the size as needed */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* 3D effect */
    margin-right: 10px; /* Space between image and title */
}

.button-group-custom {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.add-row-button-custom,
.delete-button-custom,
.export-button-custom,
.delete-collection-button-custom {
    flex: 1;
    margin: 0 10px;
    white-space: nowrap;
}

/* Mobile Responsive Adjustments */
@media (max-width: 768px) {
    .table-container-custom table, .table-container-custom thead, .table-container-custom tbody, .table-container-custom th, .table-container-custom td, .table-container-custom tr {
        display: block;
    }

        .table-container-custom thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

    .table-container-custom tr {
        border: 1px solid #ccc;
    }

    .table-container-custom td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
    }

        .table-container-custom td:before {
            position: absolute;
            top: 6px;
            left: 6px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
        }

        .table-container-custom td:nth-of-type(1):before {
            content: "Delete Row";
        }

        .table-container-custom td:nth-of-type(2):before {
            content: "News Search";
        }

        .table-container-custom td:nth-of-type(3):before {
            content: "Price Search";
        }

        .table-container-custom td:nth-of-type(4):before {
            content: "Base Name";
        }

        .table-container-custom td:nth-of-type(5):before {
            content: "Title";
        }

        .table-container-custom td:nth-of-type(6):before {
            content: "Price";
        }

        .table-container-custom td:nth-of-type(7):before {
            content: "Cost";
        }

        .table-container-custom td:nth-of-type(8):before {
            content: "Qty";
        }

        .table-container-custom td:nth-of-type(9):before {
            content: "Date";
        }

        .table-container-custom td:nth-of-type(10):before {
            content: "Gain/Loss";
        }

        .table-container-custom td:nth-of-type(11):before {
            content: "% Gain/Loss";
        }

        .table-container-custom td:nth-of-type(12):before {
            content: "Tags";
        }
}

/* Chart Container */
.chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    border: 5px solid peru; /* Changed border color to peru */
    padding: 10px;
    margin-top: 20px;
}

/* Footer */
.footer {
    background-color: peru;
    height: 50px; /* Adjust height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.action-image {
    width: 50px; /* Adjust the size as needed */
    height: 50px; /* Adjust the size as needed */
    margin: 0 10px; /* Adjust spacing as needed */
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 320px; /* Adjusted width */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-height: 400px; /* Adjusted max-height to fit the content */
    overflow: auto; /* Enable scrolling if content overflows */
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.modal-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

    .modal-form label {
        display: flex;
        flex-direction: column;
        font-weight: bold;
        width: 100%;
    }

    .modal-form input {
        padding: 5px;
        font-size: 16px;
        border-radius: 5px;
        border: 1px solid #ccc;
        width: 100%;
    }

.modal-buttons {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
}

    .modal-buttons button {
        padding: 10px 30px; /* Increased padding to fit text */
        font-size: 16px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        background-color: #a0522d;
        color: white;
        margin: 0 10px;
        text-align: center; /* Center text */
        width: 100px; /* Set a fixed width to ensure button text is fully visible */
    }

        .modal-buttons button:hover {
            background-color: #d2691e;
        }

.file-list {
    margin-top: 20px;
}

    .file-list ul {
        list-style: none;
        padding: 0;
    }

    .file-list li {
        margin: 10px 0;
    }

    .file-list button {
        padding: 10px 30px;
        font-size: 16px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        background-color: #a0522d;
        color: white;
        text-align: center;
    }

        .file-list button:hover {
            background-color: #d2691e;
        }

.file-link {
    background: none;
    border: none;
    color: peru;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
    padding: 5px 0; /* Add padding if needed */
}

    .file-link:hover {
        text-decoration: none;
    }

.psa-page-container-custom {
    text-align: center;
    padding: 20px;
    padding-top: 80px; /* Adjust this value if needed to accommodate the navbar */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.psa-title-container-custom {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.psa-title-custom {
    margin-right: 20px;
}

.psa-image-custom {
    border-radius: 15px;
    width: 100px; /* Ensures the image is a square */
    height: 100px; /* Ensures the image is a square */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: perspective(500px) rotateY(-10deg);
}

.psa-description-custom {
    font-weight: bold;
    color: peru;
    margin-bottom: 20px;
}

.psa-form-container-custom {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.psa-input-custom {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 250px;
    font-size: 16px;
    margin-bottom: 20px;
}

.psa-submit-button-custom {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: peru;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 20px;
}

.psa-response-custom {
    margin-top: 20px;
    text-align: left;
    width: 80%;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow-x: auto; /* Add horizontal scroll for mobile view */
}

.psa-response-table-custom {
    width: 100%;
    border-collapse: collapse;
}

    .psa-response-table-custom th,
    .psa-response-table-custom td {
        border: 1px solid peru;
        padding: 8px;
        text-align: left;
    }

    .psa-response-table-custom th {
        background-color: peru;
        color: white;
    }

/* Mobile optimization */
@media (max-width: 768px) {
    .psa-title-container-custom {
        flex-direction: column;
    }

    .psa-title-custom {
        font-size: 1.5em;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .psa-image-custom {
        width: 80px;
        height: 80px;
    }

    .psa-input-custom {
        width: 80%;
    }

    .psa-submit-button-custom {
        width: 80%;
    }

    .psa-response-table-custom th,
    .psa-response-table-custom td {
        font-size: 14px;
    }
}

/* Custom RSS Feed Page Styles */
.page-rss-custom {
    padding: 20px;
    padding-top: 120px; /* Adjust padding to shift down the page */
}

.header-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

    .header-custom img {
        width: 50px; /* Adjust size as needed */
        height: 50px; /* Adjust size as needed */
        border-radius: 10px; /* Rounded edges */
        margin-right: 20px;
    }

    .header-custom h1 {
        margin: 0;
    }

.search-sort-custom {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

    .search-sort-custom input {
        width: 60%;
        padding: 10px;
        font-size: 16px;
    }

.sort-buttons-custom button {
    margin-left: 10px;
    padding: 10px;
    font-size: 16px;
}

.articles-custom {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.article-custom {
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    background-color: #fff;
}

    .article-custom h2 {
        margin: 0 0 10px;
    }

    .article-custom p {
        margin: 5px 0;
    }

    .article-custom a {
        color: #007bff;
        text-decoration: none;
    }

.page-card-hedge {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    padding-top: 60px; /* Adjust this value according to your navigation bar height */
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}

.header-card-hedge {
    display: flex;
    align-items: center;
    justify-content: center;
}

    .header-card-hedge h1 {
        margin-right: 20px;
        text-align: center;
    }

.header-image-card-hedge {
    border-radius: 10px;
    max-width: 100px; /* Reduced by 50% */
    max-height: 100px; /* Reduced by 50% */
}

.description-card-hedge {
    color: peru;
    font-weight: bold;
    margin: 20px 0;
    text-align: center;
}

.search-box-card-hedge {
    width: 100%;
    max-width: 500px;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
}

.download-icon-card-hedge {
    max-width: 50px;
    max-height: 50px;
    cursor: pointer; /* Added cursor style for download icon */
}

.api-response-container {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    max-width: 600px;
    width: 100%;
    overflow-x: auto;
}

    .api-response-container h2 {
        margin-bottom: 10px;
    }

    .api-response-container pre {
        white-space: pre-wrap;
        word-wrap: break-word;
    }

.error-message {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid red;
    border-radius: 10px;
    background-color: #ffe6e6;
    color: red;
    max-width: 600px;
    width: 100%;
    text-align: center;
}

.coming-soon-image {
    margin-top: 20px;
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.source-description-card-hedge {
    text-align: justify;
    text-align-last: center;
    margin-top: auto;
    margin-bottom: 20px; /* Adjust margin as needed */
    width: 80%; /* Adjust width as needed */
}

/* Responsive design */
@media (max-width: 768px) {
    .page-card-hedge {
        padding-top: 80px; /* Adjust for mobile navigation bar height */
    }

    .header-card-hedge h1 {
        font-size: 1.5rem;
        text-align: center;
        margin-right: 0;
    }

    .description-card-hedge {
        font-size: 0.9rem;
    }

    .search-box-card-hedge {
        font-size: 0.9rem;
    }

    .download-icon-card-hedge {
        width: 40px;
    }
}


.profile-page {
    position: relative;
}

.video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 25vh; /* Take up the top quarter of the page */
    overflow: hidden;
    z-index: -1;
}

.background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-container {
    padding-top: 25vh; /* Adjust this value based on the height of the video */
}

.profile-header {
    display: flex;
    align-items: center;
}

    .profile-header h1 {
        margin-right: 20px;
    }

.profile-image {
    width: 50px; /* Adjust the size as needed */
    height: auto;
}

.peru-container {
    background-color: peru;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: white; /* Change text color to white */
    font-weight: bold; /* Make text bold */
    position: relative; /* Ensure the contact us link positions correctly */
}

.profile-info-column {
    width: 45%;
}

.form-group {
    margin-bottom: 15px;
}

    .form-group label {
        display: block;
        margin-bottom: 5px;
    }

    .form-group input,
    .date-picker-input {
        width: 100%;
        padding: 8px;
        border: none;
        border-radius: 4px;
    }

.date-of-birth-group {
    display: flex;
    flex-direction: column; /* Align the date picker below the label */
    align-items: flex-start;
}

.save-profile-group-right {
    position: absolute;
    bottom: 10px; /* Align with the CONTACT US link */
    right: 20px; /* Adjust this value to place the button at the right */
}

.save-profile-button {
    background-color: white;
    color: peru;
    border: 2px solid white;
    border-radius: 5px;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    width: 150px; /* Adjust width to ensure text is fully displayed */
    text-align: center;
}

    .save-profile-button:hover {
        background-color: #f2f2f2;
    }

.upload-section {
    margin-top: 20px;
}

.upload-container {
    display: flex;
    align-items: flex-start; /* Align items at the top */
}

.upload-label {
    display: inline-block;
    margin-bottom: 10px;
    font-weight: bold;
}

.upload-button {
    display: none; /* Hide the default file input */
}

.upload-image-label {
    display: block;
    cursor: pointer;
    margin-top: 10px; /* Adjust the space between the text and the image */
}

.upload-image {
    width: 50px; /* Adjust the size as needed */
    height: auto;
    border: 2px solid white; /* Add a white border */
    padding: 5px;
    background-color: white;
}

.display-image-container {
    margin-left: 20px; /* Adjust spacing as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px; /* Adjust the height as needed */
}

.display-image {
    max-width: 100%;
    max-height: 100%;
    border: 2px solid white; /* Add a white border */
    border-radius: 5px;
    background-color: white;
}

.contact-us {
    position: absolute;
    bottom: 10px; /* Move to the bottom of the peru container */
    left: 50%;
    transform: translateX(-50%);
    font-size: 32px; /* Increase font size */
}

    .contact-us a {
        color: white;
        font-weight: bold;
        text-decoration: none;
    }

        .contact-us a:hover {
            text-decoration: underline;
        }

.success-message {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: green;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

    .success-message button {
        background: none;
        border: none;
        color: white;
        font-size: 16px;
        cursor: pointer;
    }

.header-content-custom {
    display: flex;
    align-items: center;
}

.display-name-custom {
    margin-left: 10px;
    font-size: 1.2em;
    font-weight: bold;
    color: peru;
}

/* Styles for the PageWheel.js component */
.page-wheel-container {
    text-align: center;
    padding-top: 100px; /* Adjust to ensure the title is not hidden by the navbar */
}

.page-wheel-text {
    font-size: 18px;
    color: peru;
    font-weight: bold;
    margin-bottom: 10px; /* Reduced the space under the paragraph by 50% */
}

.prize-info-container {
    border: 2px solid peru;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    color: peru;
}

.pie-chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 200px; /* Create a large space above the pie chart */
    position: relative;
    transform: scale(1.5625); /* Scale the pie chart by another 25% */
}

.pie-chart {
    transition: transform 7s ease-out; /* 7 seconds duration */
    max-width: 400px; /* Ensure the chart is not too large */
    max-height: 400px; /* Ensure the chart is not too large */
}

.spinning {
    pointer-events: none; /* Disable interactions while spinning */
}

.spin-button {
    margin-top: 150px; /* Create a larger space between the pie chart and the button */
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    background-color: peru;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

    .spin-button:disabled {
        background-color: gray;
        cursor: not-allowed;
    }

.wheel-and-marker-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.wheel-marker {
    position: absolute;
    transform: rotate(90deg); /* Rotate the marker to point right */
    transform-origin: center;
    left: 100%; /* Position to the right of the wheel */
    top: 50%;
    width: 40px;
    height: 40px;
    transform: translateY(-50%) rotate(90deg);
}

.selected-slice-text {
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
    color: peru;
}

.hover-text-container {
    margin-top: 30px;
}

    .hover-text-container h2 {
        font-size: 20px;
        color: peru;
        font-weight: bold;
    }

    .hover-text-container ul {
        list-style-type: none;
        padding: 0;
    }

    .hover-text-container li {
        font-size: 18px;
        color: peru;
        margin-bottom: 5px;
    }

.close-button {
    width: 100%;
    padding: 10px;
    background-color: peru;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-top: 20px; /* Adjust as necessary for spacing */
}

.shop-link {
    color: blue;
    text-decoration: underline;
    margin-top: 10px; /* Adjust as necessary for spacing */
}

.add-comment-button {
    width: 150px; /* Adjust the width as needed */
}

/* Styles for the Spin The Wheel section */
.spin-wheel-container {
    margin-top: 20px; /* Add space above the spin wheel section */
    text-align: center; /* Center align the text */
}

.spin-wheel-link {
    font-size: 24px; /* Increase the font size */
    font-weight: bold; /* Make the text bold */
    color: peru; /* Use the same color theme */
    text-decoration: none; /* Remove underline from the link */
}

    .spin-wheel-link:hover {
        text-decoration: underline; /* Add underline on hover */
        color: darkorange; /* Change color on hover */
    }

/* Styles for the new modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensures it stays on top */
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
}

.modal-card-image {
    width: 100px;
    height: auto;
    margin-bottom: 10px;
}

.modal-content form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.modal-content label {
    font-weight: bold;
}

.modal-content input,
.modal-content select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: calc(100% - 22px); /* To compensate for padding and border */
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}

    .modal-buttons button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background-color: peru;
        color: white;
        cursor: pointer;
        flex-grow: 1;
    }

        .modal-buttons button:hover {
            background-color: #d2691e;
        }

/* Styles for the price data table */
.price-data-table {
    width: 100%;
    border-collapse: collapse;
}

    .price-data-table th,
    .price-data-table td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
    }

    .price-data-table th {
        background-color: peru;
        color: white;
    }

/* Additional styles for card hedge table to avoid conflicts */
.card-hedge-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

    .card-hedge-table th,
    .card-hedge-table td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
    }

    .card-hedge-table th {
        background-color: peru;
        color: white;
    }

.card-image {
    width: 100px;
    height: auto;
}

.error-message {
    color: red;
    margin-top: 20px;
    text-align: center;
}

.source-description-card-hedge {
    margin-top: 20px;
    font-style: italic;
    text-align: center;
}

/* Table container with scroll functionality */
.table-container {
    max-height: 300px; /* Adjust height to show 6 rows approximately */
    overflow-y: auto;
}

/* Combined chart and table container */
.chart-table-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    width: 100%;
}

.chart-container {
    flex: 1;
    width: 100%;
    height: 500px; /* Increase the height of the chart container */
}

/* Main content container for centering */
.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    max-width: 1200px; /* Adjust this width as necessary */
    padding-top: 60px; /* Adjust this value to move the content down */
}

/* Card summary container */
.card-summary-container {
    display: flex;
    background-color: white;
    padding: 10px;
    border: 2px solid peru;
    border-radius: 10px;
    margin-bottom: 20px;
    text-align: left;
    width: 100%; /* Ensure it takes full width of the main content */
}

.card-summary-details {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.card-summary {
    margin: 10px;
}

    .card-summary h3 {
        margin-top: 0;
        text-align: center;
    }

.summary-card-image {
    width: 150px;
    height: auto;
    margin-left: 20px;
}

/* Icon container and hover text */
.icon-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.icon-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

    .icon-wrapper img {
        width: 30px;
        height: auto;
    }

    .icon-wrapper .hover-text {
        visibility: hidden;
        width: 120px;
        background-color: #555;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        bottom: 125%; /* Position the tooltip above the icon */
        left: 50%;
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.3s;
    }

        .icon-wrapper .hover-text::after {
            content: "";
            position: absolute;
            top: 100%; /* At the bottom of the tooltip */
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: #555 transparent transparent transparent;
        }

    .icon-wrapper:hover .hover-text {
        visibility: visible;
        opacity: 1;
    }

/* Styles for mobile responsiveness */
@media (max-width: 768px) {
    .modal-content {
        width: 90%;
        padding: 15px;
    }

    .card-hedge-table,
    .price-data-table {
        font-size: 12px;
    }

    .card-summary-container {
        padding: 5px;
    }

    .card-summary h3 {
        font-size: 16px;
    }

    .card-summary p {
        font-size: 14px;
    }

    .chart-container {
        margin-top: 10px;
    }

    .search-box-card-hedge {
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
        font-size: 16px;
    }

    .modal-buttons button {
        padding: 10px;
        font-size: 14px;
    }

    .card-summary-details {
        flex-direction: column;
    }

    .summary-card-image {
        margin-left: 0;
        margin-top: 10px;
        width: 100%;
        height: auto;
    }

    .chart-table-container {
        flex-direction: column;
        width: 100%; /* Ensure full width on mobile */
        height: auto;
    }

        .chart-table-container .table-scroll,
        .chart-table-container .chart-container {
            max-width: 100%;
            height: auto;
        }
}

.example-text {
    margin-top: 10px;
    font-style: italic;
    color: gray;
}

.scroll-container {
    border: 2px solid peru;
    max-height: 200px; /* Adjust height as needed */
    overflow-y: auto;
    margin-top: 10px;
    padding: 10px;
}

.comment-share-text {
    font-weight: bold;
    color: peru;
    margin-top: 20px;
}

.comment-input textarea {
    width: 100%;
    height: 80px;
    margin-bottom: 10px;
}

.comment-input button {
    background-color: peru;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.comments-list .comment {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}

.comments-list .comment-actions button {
    margin-right: 10px;
    background-color: peru;
    color: white;
    border: none;
    padding: 5px;
    cursor: pointer;
}

.page-profile {
    margin: 20px;
}

.profile-form label {
    display: block;
    margin-bottom: 10px;
}

.profile-form input {
    display: block;
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.profile-form button {
    background-color: peru;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.page-rss-custom {
    text-align: center; /* Center align the content */
}

.sort-images-custom {
    display: flex;
    justify-content: center;
    gap: 30px; /* Add spacing between the images */
    margin: 20px 0; /* Add margin for vertical spacing */
}

.sort-icon {
    width: 40px; /* Increase the size of the images */
    height: 40px; /* Increase the size of the images */
    cursor: pointer;
    vertical-align: middle;
}

.load-more-image {
    cursor: pointer;
    width: 50px; /* adjust size as needed */
    margin: 20px auto;
    display: block;
}

    .load-more-image:hover {
        opacity: 0.8;
    }

.image-filters {
    display: flex;
    gap: 10px;
    align-items: center;
}

.filter-image {
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.background-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.wheel-text, .page-wheel-text, .prize-info-container p, .modal-content h2, .modal-content p {
    color: white;
    font-weight: bold;
}

.search-input-container input {
    margin-right: 10px;
    width: 400px; /* Adjust the width to double the original size */
}

/* Modal styles */
.share-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    width: fit-content;
}

.share-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.share-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.share-button {
    position: relative;
    display: inline-block;
}

.tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position above the icon */
    left: 50%;
    margin-left: -60px; /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

.share-button:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.close-button {
    display: block;
    width: 100px;
    margin: 10px auto 0;
    padding: 10px;
    background-color: peru;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
}

..price-search-images {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    border: none; /* Ensure no border is applied */
}

.image-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: none; /* Ensure no border is applied */
}

.search-image {
    width: 30px; /* Adjust the size as necessary */
    height: auto;
    border: none; /* Ensure no border is applied */
}

.page-podcasts {
    padding: 100px 20px 20px 20px; /* Added top padding to avoid the navbar */
}

    .page-podcasts h1 {
        text-align: center;
        margin-bottom: 20px;
    }

.page-description {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.2em;
    color: peru;
}

.select-container {
    width: 80%;
    max-width: 600px;
    margin: 0 auto 20px auto;
}

.container-title {
    text-align: center;
    margin-bottom: 10px;
    font-size: 1.5em;
    color: peru;
    font-weight: bold;
}

.podcast-container {
    border: 2px solid peru;
    padding: 20px;
    margin: 20px auto; /* Added margin to separate containers */
    width: 90%;
    max-width: 800px;
}

@media (max-width: 768px) {
    .page-podcasts {
        padding: 80px 10px 10px 10px;
    }

    .select-container {
        width: 100%;
    }

    .podcast-container {
        width: 100%;
    }
}

.tables-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    padding: 10px;
    border: 2px solid peru;
}

.table-sub-container {
    flex: 1 1 calc(33.333% - 40px);
    margin: 10px;
    padding: 10px;
    border: 1px solid peru;
}

.styled-table {
    width: 100%;
    border-collapse: collapse;
}

    .styled-table th,
    .styled-table td {
        border: 1px solid peru;
        padding: 8px;
        text-align: left;
    }

    .styled-table th {
        background-color: peru;
        color: white;
    }

.table-title {
    text-align: center;
    color: peru;
    font-weight: bold;
    margin-top: 20px;
}

.sport-title {
    text-align: center;
    color: peru;
    font-weight: bold;
    margin-bottom: 10px;
}

.new-container {
    border: 2px solid peru;
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.sub-container {
    border: 2px solid peru;
    margin: 10px;
    padding: 10px;
    flex: 1 1 30%;
    box-sizing: border-box;
    max-width: 30%;
}

.table-title {
    background-color: peru;
    color: white;
    padding: 5px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
}

.table-header {
    background-color: peru;
    color: white;
    font-weight: bold;
    text-align: center;
}

.table-cell {
    border: 1px solid peru;
    padding: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px; /* Fixed width for table cells */
    box-sizing: border-box;
    text-align: center;
}

.table-cell-player {
    max-width: 200px; /* Fixed width for the Player column */
    text-align: left; /* Left align for the Player column */
}

tbody tr {
    height: 40px; /* Fixed height for table rows to ensure consistency */
}

.new-stats-container {
    border: 2px solid peru;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
}

.leaders-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.leaders-container {
    border: 2px solid peru;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    width: 30%;
}

.page-leaders {
    padding: 20px;
    margin-top: 100px; /* Adjust as needed to prevent content from being hidden by the navbar */
    text-align: center;
}

    .page-leaders h1 {
        font-size: 2.5rem;
        color: peru;
    }

    .page-leaders p {
        font-size: 1.2rem;
        margin-bottom: 20px;
    }

.vertical-leaders {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.large-container {
    border: 2px solid peru;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    box-sizing: border-box; /* Include padding and border in element's total width and height */
    margin-bottom: 20px;
    text-align: center;
}

    .large-container h2 {
        margin-top: 0;
        color: peru;
    }

.page-csi {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

    .page-csi .content-wrapper {
        margin-top: 60px; /* Adjust this value as needed to ensure the title is not hidden by the nav bar */
    }

    .page-csi table {
        border-collapse: collapse;
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        border: 1px solid peru;
    }

    .page-csi th, .page-csi td {
        border: 1px solid peru;
        padding: 8px;
        text-align: center;
    }

    .page-csi th {
        background-color: peru;
        color: white;
        font-weight: bold;
    }

    .page-csi .positive {
        color: green;
    }

    .page-csi .negative {
        color: red;
    }

    .page-csi h1 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .page-csi p {
        font-size: 16px;
        margin-bottom: 20px;
    }

@media (max-width: 600px) {
    .page-csi h1 {
        font-size: 20px;
    }

    .page-csi p {
        font-size: 14px;
    }

    .page-csi th, .page-csi td {
        font-size: 12px;
        padding: 6px;
    }
}

/* Add these styles at the bottom of App.css */

/* Title Container Styles */
.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-image {
    width: 50px; /* Adjust the width as needed */
    height: auto;
    margin-right: 10px; /* Adjust the space between the image and the title */
}

.longhorns-image-wrapper {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1000; /* Ensure the wrapper is on top of other elements */
}

.longhorns-image {
    width: 100px;
    height: auto;
}

/* PageSnapshot styles */
.page-snapshot {
    padding: 20px;
}

.page-title {
    font-size: 2em;
    margin-bottom: 10px;
}

.page-description {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.stat-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.stat-category {
    border: 2px solid peru;
    padding: 10px;
    border-radius: 5px;
}

.category-title {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.category-description {
    font-size: 1em;
}

.left-align {
    text-align: left;
}

.leaders-page {
    padding: 20px;
    padding-top: 80px; /* Adjust to ensure content isn't hidden by the navbar */
    text-align: center; /* Center the content */
}

.leaders-container {
    border: 2px solid peru;
    padding: 20px;
    margin-top: 20px;
    border-radius: 8px;
    display: inline-block; /* Ensure container width adjusts to content */
    width: 80%; /* Adjust width as needed */
}

    .leaders-container h2 {
        margin: 0;
        padding: 0;
        color: peru;
    }

.leaders-subcontainers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* Adjust spacing between subcontainers */
    margin-top: 20px;
}

.leaders-subcontainer {
    border: 2px solid peru;
    border-radius: 8px;
    padding: 10px;
    margin: 10px;
    flex: 0 0 30%; /* Adjust width as needed */
}

.leaders-table, .empty-table {
    width: 100%;
    border-collapse: collapse;
}

    .leaders-table th, .empty-table th,
    .leaders-table td, .empty-table td {
        border: 1px solid peru;
        padding: 8px;
        text-align: left;
    }

    .leaders-table th, .empty-table th {
        background-color: peru;
        color: white;
        font-weight: bold;
    }

        /* Center text in Header 2 columns */
        .leaders-table th:nth-child(2), .empty-table th:nth-child(2),
        .leaders-table td:nth-child(2), .empty-table td:nth-child(2) {
            text-align: center;
        }

/* Center all columns except the Name column */
.full-table th:not(:first-child),
.full-table td:not(:first-child) {
    text-align: center;
}

.scrollable-container {
    max-height: 400px; /* Adjust height as needed */
    overflow: auto;
    margin-top: 20px;
    border: 2px solid peru;
    padding: 10px;
    border-radius: 8px;
}

/* New CSS for the Select component */
.leaders-multi-select {
    width: 100%;
}

.leaders-select__control {
    min-height: 30px; /* Adjust as needed */
    font-size: 14px; /* Adjust as needed */
}

.leaders-select__menu {
    z-index: 1000;
}

.leaders-select__option {
    font-size: 14px; /* Adjust as needed */
    color: black; /* Change text color to black */
}

.leaders-select__option--is-focused {
    background-color: peru;
    color: black; /* Ensure focused option text color is black */
}

.leaders-select__option--is-selected {
    background-color: peru;
    color: black; /* Ensure selected option text color is black */
}

.pricechart-page-price-charts {
    padding: 20px;
    padding-top: 80px; /* Shift down the page to avoid hiding the title behind the navigation bar */
}

.pricechart-title {
    margin-bottom: 10px;
    font-size: 1.5rem;
    text-align: center;
}

.pricechart-description {
    margin-bottom: 20px;
    font-size: 1rem;
    text-align: center;
}

.pricechart-player-select {
    margin-bottom: 20px;
}

.pricechart-chart-container {
    border: 2px solid peru;
    padding: 20px;
    width: 100%; /* Make sure the chart container takes the full width */
    max-width: 100%; /* Remove fixed max-width for better responsiveness */
    overflow-x: auto; /* Enable horizontal scrolling if needed */
}

.pricechart-summary-container {
    width: 100%; /* Match the width of the chart container */
    max-width: 100%; /* Remove fixed max-width for better responsiveness */
    overflow: auto; /* Enable scrolling for the container */
    max-height: 600px; /* Optional: Set a max height for vertical scrolling */
    border: 1px solid peru; /* Add peru border around the container */
    padding: 10px; /* Optional: Add some padding inside the container */
}

.pricechart-summary-table {
    width: 100%;
    border-collapse: collapse;
    overflow-x: auto; /* Enable horizontal scrolling for the table */
}

    .pricechart-summary-table th,
    .pricechart-summary-table td {
        padding: 10px;
        border: 1px solid peru;
    }

    .pricechart-summary-table th {
        background-color: peru;
        color: white;
        font-weight: bold;
    }

    .pricechart-summary-table td.positive {
        color: green;
    }

    .pricechart-summary-table td.negative {
        color: red;
    }

.pricechart-disclosure-container {
    width: 100%; /* Match the width of the chart container */
    max-width: 100%; /* Remove fixed max-width for better responsiveness */
    margin-top: 20px;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
    .pricechart-title {
        font-size: 1.2rem;
    }

    .pricechart-description {
        font-size: 0.9rem;
    }

    .pricechart-chart-container {
        padding: 10px;
    }

    .pricechart-summary-table th,
    .pricechart-summary-table td {
        padding: 5px;
    }
}

.leaders-table, .pivot-table {
    width: 100%;
    border-collapse: collapse;
}

.sticky-header th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: peru;
    color: white;
    z-index: 1;
}

.scrollable-container, .scrollable-table {
    max-height: 400px;
    overflow-y: auto;
    margin-top: 20px;
}

.player-image {
    max-height: 150px;
    margin-left: 20px;
    object-fit: contain;
    position: relative;
    top: 20px; /* Move the image lower */
    left: -10px; /* Move the image towards the center */
    transform: translateX(-50%); /* Further adjust to center */
}

.history-container {
    padding: 20px;
    margin: auto;
    max-width: 1200px;
}

.page-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
}

.content-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.text-container {
    flex: 1;
    min-width: 300px;
    padding: 20px;
}

.image-container {
    flex: 1;
    min-width: 300px;
    padding: 20px;
}

.content-section h2 {
    font-size: 1.5em;
    margin-top: 20px;
}

.content-section p {
    font-size: 1em;
    line-height: 1.6;
}

.expandable-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    transition: transform 0.2s;
    max-height: 300px; /* Adjust as necessary to fit the design */
    object-fit: contain; /* Ensures the images maintain aspect ratio */
}

    .expandable-image:hover {
        transform: scale(1.05);
    }

@media (max-width: 768px) {
    .content-wrapper {
        flex-direction: column;
    }

    .text-container, .image-container {
        min-width: 100%;
    }

    .page-title {
        font-size: 1.5em;
    }

    .content-section h2 {
        font-size: 1.2em;
    }

    .content-section p {
        font-size: 0.9em;
    }
}

@media (max-width: 480px) {
    .page-title {
        font-size: 1.2em;
    }

    .content-section h2 {
        font-size: 1em;
    }

    .content-section p {
        font-size: 0.8em;
    }
}

.glossary-container {
    padding-top: 80px; /* Adjust this value if your navigation bar height is different */
    padding-left: 20px;
    padding-right: 20px;
}

.glossary-title {
    text-align: center;
    margin-bottom: 40px;
}

.glossary-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.glossary-item {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.glossary-term {
    font-size: 1.5em;
    color: #333;
}

.glossary-definition {
    font-size: 1em;
    color: #666;
}

.page-grading {
    padding-top: 80px; /* Adjust this value based on your navigation bar height */
    padding-left: 20px;
    padding-right: 20px;
}

.page-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.5rem; /* Match with existing styles */
    color: #333;
}

.intro-paragraph {
    background-color: #f9f9f9; /* Background color to match existing style */
    font-size: 1rem;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 40px;
    text-align: justify;
}

.centered-image {
    text-align: center;
    margin-bottom: 40px;
}

    .centered-image img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
    }

.company-section {
    margin-bottom: 40px;
    background-color: #fff; /* Background color for sections */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

    .company-section img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto 20px;
    }

    .company-section h2 {
        text-align: center;
        margin-bottom: 10px;
        color: #333;
    }

    .company-section p {
        font-size: 1rem;
        color: #333;
        text-align: justify;
    }

@media (max-width: 768px) {
    .page-title {
        font-size: 2rem;
    }

    .intro-paragraph, .company-section p {
        font-size: 0.9rem;
    }

    .company-section {
        padding: 15px;
    }
}

.company-content {
    margin-bottom: 20px; /* Adjust the value as needed */
    line-height: 1.6; /* Adjust the value as needed */
}

.page-grading img {
    max-width: 100%;
    height: auto;
}

.image-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
}

.comparison-image {
    max-height: 200px; /* Adjust height as needed */
    max-width: 150px; /* Adjust width as needed */
    object-fit: contain;
    margin: 0 10px;
}

/* Add this section to the existing CSS file */

/* Styles for resizing and centering the PSA images */
.psa-section .resized-image {
    max-width: 50%; /* Adjust the percentage as needed */
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

/* Styles for the PageGrading table of contents and sections */
.page-grading .intro-paragraph {
    margin: 20px 0;
    padding: 10px;
    font-size: 1em;
    line-height: 1.5em;
    text-align: justify;
}

.page-grading .table-of-contents {
    margin: 20px 0;
}

    .page-grading .table-of-contents h2 {
        font-size: 1.5em;
        margin-bottom: 10px;
    }

    .page-grading .table-of-contents ul {
        list-style: none;
        padding: 0;
    }

    .page-grading .table-of-contents li {
        margin: 5px 0;
    }

    .page-grading .table-of-contents a {
        text-decoration: none;
        color: #007BFF;
    }

        .page-grading .table-of-contents a:hover {
            text-decoration: underline;
        }

.page-grading .company-section {
    margin-bottom: 20px;
}

    .page-grading .company-section h2 {
        font-size: 1.5em;
        margin-bottom: 10px;
    }

.page-grading .company-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
}

    .page-grading .company-images img {
        max-width: 100%;
        height: auto;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

.additional-arena-club-image {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: block; /* Ensures the image is a block element */
    margin: 0 auto; /* Centers the image horizontally */
}

/* Add this to your App.css */

.page-one-of-one {
    padding: 20px;
}

h1 {
    text-align: center;
    font-size: 24px;
}

p {
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px;
}

.search-and-select-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.search-box {
    width: 100%;
    max-width: 400px;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 14px;
}

.multi-select {
    width: 100%;
    max-width: 400px;
    margin-bottom: 10px;
}

.reset-button {
    width: 50px;
    height: 50px;
    cursor: pointer;
    background: none; /* Remove background color */
    border: none; /* Remove border */
    padding: 0; /* Remove padding */
}

.card-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.card-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px;
    width: 100%;
    max-width: 300px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

    .card-item a {
        text-decoration: none;
        color: inherit;
    }

.card-image {
    width: 100%;
    height: auto;
}

.card-details {
    padding: 10px;
    text-align: center;
}

    .card-details h2 {
        font-size: 18px;
        margin: 10px 0;
    }

    .card-details p {
        font-size: 14px;
        margin: 5px 0;
    }

@media (min-width: 600px) {
    .search-and-select-container {
        flex-direction: row;
        justify-content: center;
    }

    .search-box,
    .multi-select {
        margin-right: 10px;
        margin-bottom: 0;
    }
}

/* Styles for the One-of-One Page */
.page-one-of-one {
    margin-top: 80px;
    padding: 20px;
}

.card-gallery {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
}

.card-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.card-details h2 {
    font-size: 1.2em;
    margin: 10px 0;
}

.card-details p {
    margin: 5px 0;
}

.search-and-select-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.search-box {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    max-width: 300px;
    margin-bottom: 10px;
}

.multi-select {
    width: 100%;
    max-width: 300px;
    margin-bottom: 10px;
}

.reset-button {
    cursor: pointer;
    margin-top: 10px;
    width: 30px;
    height: 30px;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

    .pagination button {
        padding: 10px 15px;
        margin: 0 5px;
        background-color: peru;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
    }

        .pagination button:hover {
            background-color: #a8642d;
        }

/* Media queries for responsiveness */
@media (max-width: 600px) {
    .card-gallery {
        grid-template-columns: repeat(2, 1fr);
    }

    .card-item {
        padding: 5px;
    }

    .card-details h2 {
        font-size: 1em;
    }

    .card-details p {
        font-size: 0.8em;
    }

    .pagination button {
        padding: 5px 10px;
        font-size: 14px;
    }
}

.products-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 16px;
}

@media (max-width: 1200px) {
    .products-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 992px) {
    .products-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .products-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 576px) {
    .products-grid {
        grid-template-columns: 1fr;
    }
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.page-number {
    margin: 0 5px;
    padding: 10px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
}

    .page-number:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .page-number.active {
        font-weight: bold;
        background-color: #d0d0d0;
    }

.page-info {
    margin: 0 10px;
    font-weight: bold;
}

.filter-wrapper, .products-title {
    text-align: center;
    margin-bottom: 20px;
}

.filter-container, .image-filters, .search-box, .price-filter, .tag-filter {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

    .search-box input, .price-filter input, .reset-button {
        padding: 10px;
        margin: 5px;
        border: 1px solid #ddd;
        border-radius: 4px;
        width: 200px;
        max-width: 100%;
    }

.filter-image {
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin: 5px;
}

.product-card {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
}

.product-image {
    max-width: 100%;
    height: auto;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
}

.product-title, .product-price {
    margin: 10px 0;
}

.product-link {
    text-decoration: none;
    color: #007bff;
    display: block;
    margin-top: 10px;
}

/* Add these styles at the bottom of App.css */

/* Table Wrapper for Scrollable Tables */
.table-wrapper {
    height: 500px; /* Set a fixed height for the table container */
    overflow-y: scroll; /* Enable vertical scrolling */
}

.scoreboard-table {
    width: 100%; /* Ensure the table takes the full width */
    border-collapse: collapse; /* Ensure table borders collapse */
}

.th, .td {
    padding: 8px; /* Add padding for table cells */
    border: 1px solid #ddd; /* Add border for table cells */
}

.tr:nth-child(even) {
    background-color: #f2f2f2; /* Add background color for even rows */
}

.th.sticky, .td.sticky {
    position: sticky;
    left: 0;
    background: white; /* Set background color for sticky cells */
    z-index: 1; /* Ensure sticky cells are on top */
}
